import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgSelectComponent } from '@ng-select/ng-select';
import { select, Store } from '@ngrx/store';
import { firstValueFrom, Observable, Subscription } from 'rxjs';

import { BreadcrumbsComponent } from '../../../../../components/breadcrumbs/breadcrumbs.component';
import { ButtonComponent } from '../../../../../components/button/button.component';
import { DividerComponent } from '../../../../../components/divider/divider.component';
import { IconComponent } from '../../../../../components/icon/icon.component';
import { InputComponent } from '../../../../../components/input/input.component';
import { LoadingComponent } from '../../../../../components/loading/loading.component';
import { SegmentsComponent } from '../../../../../components/segments/segments.component';
import { SiteAddressComponent } from '../../../../../components/site-address/site-address.component';
import { Address } from '../../../../../interfaces/address.interface';
import { Job } from '../../../../../interfaces/job.interface';
import { Sample } from '../../../../../interfaces/sample.interface';
import { UserRole } from '../../../../../interfaces/user.interface';
import { OrganisationUserPipe } from '../../../../../pipes/organisation-user.pipe';
import { JobActions } from '../../../../../store/actions/job.actions';
import { OrganisationActions } from '../../../../../store/actions/organisation.actions';
import { SampleActions } from '../../../../../store/actions/sample.actions';
import {
  selectJobById,
  selectJobState,
  selectJobStatePending,
} from '../../../../../store/selectors/job.selector';
import { selectAllOrganisationUsers } from '../../../../../store/selectors/organisation.selector';
import {
  selectSamplesByJobId,
  selectSampleStatePending,
} from '../../../../../store/selectors/sample.selector';
import { selectActiveOrganisationUserRole } from '../../../../../store/selectors/user.selector';
import { formatAddress } from '../../../../../utils/address';
import { MenuComponent } from '../../../user-management/components/menu/menu.component';
import { MenuActionComponent } from '../../../user-management/components/menu-action/menu-action.component';
import { JOBS_AND_TESTS_PAGE_URL } from '../../jobs-and-tests.page';
import { DeleteJobModalComponent } from './delete-job/delete-job.component';
import { CreateSampleModalComponent } from './modals/create-sample/create-sample.component';

export const JOB_PAGE_URL = 'job/:jobId';

@Component({
  standalone: true,
  templateUrl: './job.page.html',
  imports: [
    DividerComponent,
    MenuComponent,
    MenuActionComponent,
    LoadingComponent,
    CreateSampleModalComponent,
    IconComponent,
    CommonModule,
    SegmentsComponent,
    SiteAddressComponent,
    BreadcrumbsComponent,
    ButtonComponent,
    InputComponent,
    DeleteJobModalComponent,
    OrganisationUserPipe,
    NgSelectComponent,
    ReactiveFormsModule,
  ],
})
export class JobPage implements OnInit, OnDestroy {
  public job$!: Observable<Job | undefined>;
  public samples$!: Observable<Sample[] | undefined>;
  public isJobPending$ = this.store.select(selectJobStatePending);
  public isSamplesPending$ = this.store.select(selectSampleStatePending);
  public job: Job | undefined;
  public sampleMenuOpen = '';
  public showDeleteJobModal = false;
  public showCreateSampleModal = false;
  public readonly form = new FormGroup({
    userId: new FormControl('', [Validators.required]),
    customerReference: new FormControl('', [Validators.required]),
    siteAddress: new FormControl('', [Validators.required]),
  });
  public editing = {
    userId: false,
    customerReference: false,
    siteAddress: false,
  };
  public jobId = '';
  public userRole: UserRole | undefined;
  public organisationUsers: { id: string; name: string }[] = [];
  private readonly organisationUsers$ = this.store.pipe(
    select(selectAllOrganisationUsers),
  );
  private jobStateSub!: Subscription;
  private organisationUsersSub!: Subscription;
  private _view = 'details';

  @ViewChild(SiteAddressComponent)
  private readonly siteAddress!: SiteAddressComponent;

  public get view(): string {
    return this._view;
  }

  public set view(value: string) {
    this._view = value || 'details';

    if (value === 'details') {
      this.store.dispatch(JobActions.get({ id: this.jobId }));
    } else {
      this.store.dispatch(SampleActions.loadSamples({ jobId: this.jobId }));
    }
  }

  public constructor(
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly store: Store,
  ) {}

  public async ngOnInit(): Promise<void> {
    const { jobId } = await firstValueFrom(this.route.params);

    if (!jobId) {
      await this.router.navigateByUrl(JOBS_AND_TESTS_PAGE_URL);
      return;
    }

    this.jobId = jobId;
    this.job$ = this.store.select(selectJobById(this.jobId));
    this.store.dispatch(JobActions.get({ id: jobId }));

    this.job$ = this.store.select(selectJobById(jobId));
    this.samples$ = this.store.select(selectSamplesByJobId(jobId));

    this.jobStateSub = this.store
      .select(selectJobState)
      .subscribe(async ({ pending, entities }) => {
        const job = entities[this.jobId];

        if (!pending && job) {
          this.job = job;
          this.form.patchValue({
            userId: job.userId,
            customerReference: job.clientReference,
            siteAddress: job.site,
          });
        }
      });

    this.userRole = await firstValueFrom(
      this.store.pipe(select(selectActiveOrganisationUserRole)),
    );

    if (this.userRole !== 'USER') {
      this.organisationUsersSub = this.organisationUsers$.subscribe(
        (params) => {
          this.organisationUsers = [];
          this.organisationUsers = params
            .filter(({ status }) => status === 'ACTIVE')
            .map(({ userId, profile }) => ({
              id: userId,
              name: `${profile.firstName} ${profile.lastName}`,
            }));
        },
      );
    }
  }

  public ngOnDestroy(): void {
    this.jobStateSub?.unsubscribe();
    this.organisationUsersSub?.unsubscribe();
  }

  public async viewSample(id: string): Promise<void> {
    await this.router.navigateByUrl(
      `${JOBS_AND_TESTS_PAGE_URL}/job/${this.jobId}/sample/${id}`,
    );
  }

  public async updateCustomerReference(): Promise<void> {
    const { customerReference } = this.form.value;
    const job = await firstValueFrom(this.job$);

    if (!job || !customerReference) {
      throw new Error();
    }

    this.store.dispatch(
      JobActions.update({
        jobId: job.id,
        payload: {
          clientReference: customerReference,
          site: job.site,
          date: job.date,
          customerName: job.customerName,
          userId: job.userId,
        },
      }),
    );

    await this.clearEditMode();
  }

  public async updateUserId(): Promise<void> {
    const { userId } = this.form.value;
    const job = await firstValueFrom(this.job$);

    if (!job || !userId) {
      throw new Error();
    }

    this.store.dispatch(
      JobActions.update({
        jobId: job.id,
        payload: {
          clientReference: job.clientReference,
          site: job.site,
          date: job.date,
          customerName: job.customerName,
          userId,
        },
      }),
    );

    await this.clearEditMode();
  }

  public selectSiteAddress({
    line1,
    line2,
    line3,
    city,
    county,
    postalCode,
  }: Address): void {
    this.form.patchValue({
      siteAddress: formatAddress(line1, line2, line3, city, county, postalCode),
    });
  }

  public async updateSiteAddress(): Promise<void> {
    if (!this.form.controls.siteAddress.valid) {
      return;
    }

    const { siteAddress } = this.form.value;
    const job = await firstValueFrom(this.job$);

    if (!job || !siteAddress) {
      return;
    }

    this.store.dispatch(
      JobActions.update({
        jobId: job.id,
        payload: {
          clientReference: job.clientReference,
          site: siteAddress,
          date: job.date,
          customerName: job.customerName,
          userId: job.userId,
        },
      }),
    );

    await this.clearEditMode();
  }

  public async editMode(
    mode: 'customerReference' | 'siteAddress' | 'userId',
  ): Promise<void> {
    const job = await firstValueFrom(this.job$);

    this.editing = {
      userId: false,
      customerReference: false,
      siteAddress: false,
    };

    this.editing[mode] = true;

    if (mode === 'userId' && this.userRole !== 'USER') {
      this.store.dispatch(OrganisationActions.getAllUsers());
    }

    this.form.patchValue({
      userId: job?.userId ?? '',
      customerReference: job?.clientReference ?? '',
      siteAddress: job?.site ?? '',
    });

    if (mode === 'siteAddress') {
      this.siteAddress.value = '';
    }
  }

  public async clearEditMode(): Promise<void> {
    this.editing.userId = false;
    this.editing.customerReference = false;
    this.editing.siteAddress = false;
  }

  public async cancelEdit(): Promise<void> {
    await this.clearEditMode();
    this.siteAddress.value = '';
    const job = await firstValueFrom(this.job$);
    this.form.patchValue({
      customerReference: job?.clientReference ?? '',
      siteAddress: job?.site ?? '',
    });
  }

  public async deleteJobModalClosed(): Promise<void> {
    this.showDeleteJobModal = false;
    await this.router.navigateByUrl(JOBS_AND_TESTS_PAGE_URL, {
      replaceUrl: true,
    });
  }
}
